
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import { formatDate } from '@/util/global';

// API
import { detailProduct, addProduct, editProduct } from '@/api/factory/product';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
const STORAGE_STATUS = [
  {
    id: 1,
    value: '冷藏',
  },
  {
    id: 2,
    value: '冷冻',
  },
  {
    id: 3,
    value: '常温'
  }
]

@Component
export default class ProductDetail extends Mixins (Validate){
  globalFunction=useGlobalFunction().globalFunction;
    is_loading: boolean = false;
    is_edit: boolean = false;
    is_detail: boolean = false;
    product_id: any = '';
    MAX_LENGTH: number = 20;
    get storageStatus() {
      return STORAGE_STATUS
    }
    // 表单布局
    form_item_layout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };

    // 表单校验和参数
    validator_list: any[] = [
      {
        field: 'u8_code',
        validate: (value: string): boolean => {
          return /^[0-9]\d*$/.test(value);
        },
        message: '请输入正确的U8编码',
      },
      {
        field: 'name',
        message: '请输入产品名称',
      },
      {
        field: 'spec',
        message: '请输入规格',
      },
      {
        field: 'storage_condition',
        message: '请选择存储条件',
      },
      {
        field: 'guarantee_period',
        validate: (value: any) => value >= 0 && value,
        message: '请输入保质期（天）',
      },
    ];
    form: any = {
      u8_code: '',
      name: '',
      spec: '',
      storage_condition: '',
      guarantee_period: '',
      carton_size: '',
    }
    validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);

    back() {
        // this.$router.go(-1);
        this.$router.push({
          path: '/factory/product',
          query: { page: this.$route.query?.page || '1' },
        });
    }

    // 存储条件回显 
    set(list: any) {
      for (const i in list) {
        if (list[i].id == this.form.storage_condition) {
          return list[i].value;
        }
      }
    }

    // 切换
    async edit(str: boolean, type: number = 0) {
      this.is_detail = str;
      if (type == 1) {
        const res: any = await detailProduct(this.product_id);
        this.form = res.data;
      }
    }

    // 提交
    @changeLoading(['is_loading'])
    async submit(type: number) {
      if (!this.validateCommit()) {
        return;
      }
      const data: any = {
          u8_code: this.form.u8_code,
          name: this.form.name,
          spec: this.form.spec,
          storage_condition: this.form.storage_condition,
          guarantee_period: this.form.guarantee_period,
          carton_size: this.form.carton_size,
      }
      if(type == 1) {
        this.is_loading = true;
        const res: any = await editProduct(data, this.form.id);
        this.is_loading = false;
        if (res.status !== 200) {
            this.$message.error((res as any).message);
            return;
        }
        this.$message.success('编辑成功');
      } else {
        this.is_loading = true;
        const res: any = await addProduct(data);
        this.is_loading = false;
        if (res.status !== 200) {
            this.$message.error((res as any).message);
            return;
        }
        this.$message.success('添加成功');
      }
      this.clearModal();
      this.$router.push({
        path: '/factory/product',
        query: { page: this.$route.query?.page || '1' },
      });
      this.globalFunction.productManagementList()
    }
    
    // 新增/编辑 取消
    addCancel() {
        this.clearModal();
    }
    // 清空模态框数据
    clearModal() {
        // 清除校验信息
        this.form = {
            factory_name: '',
            factory_address: '',
            factory_receiver: '',
            factory_receiver_mobile: '',
        }
        this.$nextTick(() => {
            this.resetValidatorStatus();
        });
    }

    @changeLoading(['is_loading'])
    async created() {
        if (this.$route.query.id) {
          this.product_id = +this.$route.query.id;
          this.is_edit = true;
          const res: any = await detailProduct(this.product_id);
          this.form = res.data;
          this.form.create_time = formatDate(this.form.create_time)
        }
    }
}
